import Page, { PageMeta } from 'components/Layout/Page'
import { Balance, CurrencyIcon, Flex, FlexLayout, Heading, Skeleton, Text } from '@zinuswap/uikit'
import { useEffect, useState } from 'react'
import zinuAbi from 'config/abi/zinu.json'
import zinuVaultV2Abi from 'config/abi/zinuVaultV2.json'
import { ZINU } from '@zinuswap/tokens'
import { multicallv3 } from 'utils/multicall'
import { getZinuVaultAddress } from 'utils/addressHelpers'
import { useActiveChainId } from 'hooks/useActiveChainId'
import formatLocalisedCompactNumber, { formatBigNumber } from '@zinuswap/utils/formatBalance'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { usePriceZinuUsd } from 'state/farms/hooks'
import { useTranslation } from '@zinuswap/localization'
import { ZINU_PER_BLOCK } from 'config'
import { parseUnits } from '@ethersproject/units'
import { ZINU_DECIMALS } from 'config/constants'
import NftCard from './components/NftCard'
import HomeCard from './components/HomeCard'

const Home: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const { chainId: chain } = useActiveChainId()

  const zinuVaultAddress = getZinuVaultAddress(chainId)
  const zinuAddress = ZINU[chainId].address

  const [zinuData, setZinuData] = useState({
    zinuSupply: 0,
    burnedBalance: 0,
    circulatingSupply: 0,
  })

  const ZINU_MAX_SUPPLY = parseUnits('1000000000', ZINU_DECIMALS)

  useEffect(() => {
    const fetchData = async () => {
      const totalSupplyCall = { abi: zinuAbi, address: zinuAddress, name: 'totalSupply' }
      const burnedTokenCall = {
        abi: zinuAbi,
        address: zinuAddress,
        name: 'balanceOf',
        params: ['0x000000000000000000000000000000000000dEaD'],
      }
      const zinuVaultCall = {
        abi: zinuVaultV2Abi,
        address: zinuVaultAddress,
        name: 'totalLockedAmount',
      }

      const [[totalSupply], [burned], [totalLockedAmount]] = await multicallv3({
        calls: [totalSupplyCall, burnedTokenCall, zinuVaultCall],
        allowFailure: true,
        chainId,
      })

      const circulating = totalSupply.sub(burned.add(totalLockedAmount))
      const totalBurned = ZINU_MAX_SUPPLY.sub(circulating)

      setZinuData({
        // zinuSupply: totalSupply && burned ? +formatBigNumber(totalSupply.sub(totalBurned), 9, 9) : 0,
        zinuSupply: +formatBigNumber(ZINU_MAX_SUPPLY, 9, 9),
        burnedBalance: burned ? +formatBigNumber(totalBurned, 9, 9) : 0,
        circulatingSupply: circulating ? +formatBigNumber(circulating, 9, 9) : 0,
      })
    }

    fetchData()
  }, [zinuAddress, zinuVaultAddress, chainId])

  const [tokenPrice, setTokenPrice] = useState<number | null>(null)

  useEffect(() => {
    // {
    //   "id": "zombie-inu-2",
    //   "symbol": "zinu",
    //   "name": "Zombie Inu"
    // }
    const fetchTokenPrice = async () => {
      try {
        const tokenSymbol = 'zombie-inu-2' // Cambia esto al símbolo de tu token
        const vsCurrency = 'usd' // Cambia esto a la moneda en la que deseas mostrar el precio

        const response = await fetch(
          `https://api.coingecko.com/api/v3/simple/price?ids=${tokenSymbol}&vs_currencies=${vsCurrency}`,
        )
        const data = await response.json()

        const price = data[tokenSymbol][vsCurrency]
        setTokenPrice(price)
      } catch (error) {
        console.error('', error)
      }
    }

    fetchTokenPrice()
  }, [])

  const zinuPriceBusd = usePriceZinuUsd()
  const mcap = zinuPriceBusd.multipliedBy(zinuData.circulatingSupply)
  const mcapString = formatLocalisedCompactNumber(mcap.toNumber())

  return (
    <>
      <PageMeta />
      <Page>
        <FlexLayout>
          <HomeCard>
            <Flex style={{ gap: 20 }}>
              <Flex>
                <CurrencyIcon style={{ width: 48 }} color="primary" />
              </Flex>
              <Flex flexDirection="column">
                <Text color="textSubtle" mb="16px">
                  {t('Total supply')}
                </Text>
                {zinuData.zinuSupply ? (
                  <Balance decimals={0} lineHeight="1.1" fontSize="24px" bold value={zinuData.zinuSupply} />
                ) : (
                  <Skeleton height={24} width={126} my="4px" />
                )}
              </Flex>
            </Flex>
          </HomeCard>

          <HomeCard>
            <Flex style={{ gap: 20 }}>
              <Flex>
                <CurrencyIcon style={{ width: 48 }} color="primary" />
              </Flex>
              <Flex flexDirection="column">
                <Text color="textSubtle" mb="16px">
                  {t('Circulating Supply')}
                </Text>
                {zinuData.circulatingSupply ? (
                  <Balance decimals={0} lineHeight="1.1" fontSize="24px" bold value={zinuData.circulatingSupply} />
                ) : (
                  <Skeleton height={24} width={126} my="4px" />
                )}
              </Flex>
            </Flex>
          </HomeCard>

          <HomeCard>
            <Flex style={{ gap: 20 }}>
              <Flex>
                <CurrencyIcon style={{ width: 48 }} color="primary" />
              </Flex>
              <Flex flexDirection="column">
                <Text color="textSubtle" mb="16px">
                  {t('Zinu Price')}
                </Text>
                {chain === 56 && (
                  <Balance decimals={6} lineHeight="1.1" fontSize="24px" bold value={zinuPriceBusd?.toNumber()} />
                )}
                {chain === 1 && <Balance decimals={6} lineHeight="1.1" fontSize="24px" bold value={tokenPrice} />}
              </Flex>
            </Flex>
          </HomeCard>

          <HomeCard>
            <Flex style={{ gap: 20 }}>
              <Flex>
                <CurrencyIcon style={{ width: 48 }} color="primary" />
              </Flex>
              <Flex flexDirection="column">
                <Text color="textSubtle" mb="16px">
                  {t('Market cap')}
                </Text>
                {mcap?.gt(0) && mcapString ? (
                  <Heading scale="lg" color="white">
                    {t('$%marketCap%', { marketCap: mcapString })}
                  </Heading>
                ) : (
                  <Skeleton height={24} width={126} my="4px" />
                )}
              </Flex>
            </Flex>
          </HomeCard>

          <HomeCard>
            <Flex style={{ gap: 20 }}>
              <Flex>
                <CurrencyIcon style={{ width: 48 }} color="primary" />
              </Flex>
              <Flex flexDirection="column">
                <Text color="textSubtle" mb="16px">
                  {t('Burned to date')}
                </Text>
                {zinuData.burnedBalance ? (
                  <Balance decimals={0} lineHeight="1.1" fontSize="24px" bold value={zinuData.burnedBalance} />
                ) : (
                  <Skeleton height={24} width={126} my="4px" />
                )}
              </Flex>
            </Flex>
          </HomeCard>

          <HomeCard>
            <Flex style={{ gap: 20 }}>
              <Flex>
                <CurrencyIcon style={{ width: 48 }} color="primary" />
              </Flex>
              <Flex flexDirection="column">
                <Text color="textSubtle" mb="16px">
                  {t('Current emissions')}
                </Text>
                <Heading scale="lg" color="white">
                  {t('%zinuEmissions%/block', { zinuEmissions: ZINU_PER_BLOCK[chainId] })}
                </Heading>
              </Flex>
            </Flex>
          </HomeCard>
        </FlexLayout>
        <FlexLayout>
          <NftCard />
        </FlexLayout>
      </Page>
    </>
  )
}

export default Home
