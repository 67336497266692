import styled from 'styled-components'
import { Card, Flex, Heading, Skeleton, Text } from '@zinuswap/uikit'
import { useEffect, useState } from 'react'

const StyledCard = styled(Card)`
  align-self: baseline;
  max-width: 100%;
  margin: 0 0 24px 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 1280px;
    margin: 0 12px 24px;
  }
`

const CardInnerContainer = styled.div`
  flex-direction: column;
  justify-content: space-around;
  padding: 30px 24px;
`

const StyledBannerContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
`
const StyledBannerImg = styled.img`
  width: 100%;
`

const StyledFeatureImgContainer = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  
  ${({ theme }) => theme.mediaQueries.sm} {
    left: 50px;
    top: 140px;
  }
`
const StyledFeatureImg = styled.img`
  width: 60px;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 150px;
    border-radius: 20px;
    border: 4px solid ${({ theme }) => theme.colors.white};
  }
`

const NftCard: React.FC<React.PropsWithChildren> = () => {
  const [collectionData, setCollectionData] = useState({
    name: '',
    banner_image_url: '',
    description: '',
    created_date: '',
    stats: {
      total_supply: 0,
      total_volume: 0,
      num_owners: 0,
      floor_price: 0,
    },
    payment_tokens: [{ name: '', symbol: '', usd_price: 0 }],

  })

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`https://api.opensea.io/api/v1/collection/zombiemobsecretsociety`, {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })

      const result = await response.json()

      if (result?.collection) {
        setCollectionData(result?.collection)
      }
    }

    fetchData();
  }, [])

  return (
    <StyledCard>
      <CardInnerContainer>
        <StyledBannerContainer>
          <StyledBannerImg src="https://i.seadn.io/gcs/files/546c3af3a131a1b1fadacb04e7100e44.jpg?auto=format&w=640 640w, https://i.seadn.io/gcs/files/546c3af3a131a1b1fadacb04e7100e44.jpg?auto=format&w=750 750w, https://i.seadn.io/gcs/files/546c3af3a131a1b1fadacb04e7100e44.jpg?auto=format&w=828 828w, https://i.seadn.io/gcs/files/546c3af3a131a1b1fadacb04e7100e44.jpg?auto=format&w=1080 1080w, https://i.seadn.io/gcs/files/546c3af3a131a1b1fadacb04e7100e44.jpg?auto=format&w=1200 1200w, https://i.seadn.io/gcs/files/546c3af3a131a1b1fadacb04e7100e44.jpg?auto=format&w=1920 1920w, https://i.seadn.io/gcs/files/546c3af3a131a1b1fadacb04e7100e44.jpg?auto=format&w=2048 2048w, https://i.seadn.io/gcs/files/546c3af3a131a1b1fadacb04e7100e44.jpg?auto=format&w=3840 3840w" />
          <StyledFeatureImgContainer>
            <StyledFeatureImg src="https://i.seadn.io/gae/0rRqgbEAHfee51ZWv0Crstfq_o3cHB7JdOwMMG0QPKqncTtkTvtTrEaLUcUysJHeLrLQ6UgtXmJB2-8xP3p-Z2_fhgnl6MgQmOY2?w=500&auto=format" />
          </StyledFeatureImgContainer>
        </StyledBannerContainer>

        {collectionData?.name ? (
          <Heading scale="lg" color="primary" mb="24px">{collectionData?.name}</Heading>
        ) : (
          <Skeleton height={24} width={126} my="4px" />
        )}

        <Flex mb="12px" style={{ gap: 40 }}>
          <Flex style={{ gap: 10 }}>
            <Text>Created</Text>
            {collectionData?.created_date ? (
              <Text color="white" bold>
                {`${new Date(collectionData?.created_date).toLocaleString('default', { month: 'short' })} ${new Date(collectionData?.created_date).getFullYear()}`}
              </Text>
            ) : (
              <Skeleton height={24} width={126} my="4px" />
            )}
          </Flex>
        </Flex>

        {collectionData?.description ? (
          <Text color="text" mb="24px" style={{wordWrap: 'break-word'}}>{collectionData?.description}</Text>
        ) : (
          <Skeleton height={24} width={126} my="4px" />
        )}

        <Flex style={{ gap: 40 }} flexDirection={["column", "column", "row"]}>
          <Flex flexDirection="column" alignItems="center">
            {collectionData?.stats?.total_supply ? (
              <Heading color="white" scale="lg">{Math.round(collectionData?.stats?.total_supply)}</Heading>
            ) : (
              <Skeleton height={24} width={126} my="4px" />
            )}
            <Text>items</Text>
          </Flex>
          <Flex flexDirection="column" alignItems="center">
            {collectionData?.stats?.total_volume ? (
              <Heading color="white" scale="lg">{Math.round(collectionData?.stats?.total_volume)}</Heading>
            ) : (
              <Skeleton height={24} width={126} my="4px" />
            )}
            <Text>total volume</Text>
          </Flex>
          <Flex flexDirection="column" alignItems="center">
            {collectionData?.stats?.floor_price ? (
              <Heading color="white" scale="lg">{collectionData?.stats?.floor_price}</Heading>
            ) : (
              <Skeleton height={24} width={126} my="4px" />
            )}
            <Text>floor price</Text>
          </Flex>
          <Flex flexDirection="column" alignItems="center">
            {collectionData?.stats?.num_owners ? (
              <Heading color="white" scale="lg">{collectionData?.stats?.num_owners}</Heading>
            ) : (
              <Skeleton height={24} width={126} my="4px" />
            )}
            <Text>owners</Text>
          </Flex>
        </Flex>
      </CardInnerContainer>
    </StyledCard>
  )
}

export default NftCard
