import useSWR from 'swr'
import { NO_PROXY_CONTRACT } from 'config/constants'
import { useBZinuFarmBoosterContract } from 'hooks/useContract'
import { FetchStatus } from 'config/constants/types'
import { bZinuSupportedChainId } from '@zinuswap/farms/src/index'

export const useBZinuProxyContractAddress = (account?: string, chainId?: number) => {
  const bZinuFarmBoosterContract = useBZinuFarmBoosterContract()
  const isSupportedChain = bZinuSupportedChainId.includes(chainId)
  const { data, status, mutate } = useSWR(account && isSupportedChain && ['proxyAddress', account], async () =>
    bZinuFarmBoosterContract.proxyContract(account),
  )
  const isLoading = isSupportedChain ? status !== FetchStatus.Fetched : false

  return {
    proxyAddress: data,
    isLoading,
    proxyCreated: data && data !== NO_PROXY_CONTRACT,
    refreshProxyAddress: mutate,
  }
}
